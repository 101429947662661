<template>
  <BModal
    id="modal-insufficient-balance"
    ref="modal-insufficient-balance"
    no-stacking
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="md"
    hide-header
  >
    <div class="p-[36px]">
      <div class="flex justify-center">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/Icon-Error.svg"
        >
      </div>
      <div class="text-[24px] mt-[24px] text-center text-[#333333] font-semibold">
        Saldo User Tidak Cukup
      </div>
      <BButton
        class="w-full mt-[24px]"
        variant="primary"
        @click="closeModal"
      >
        Mengerti
      </BButton>
    </div>
  </BModal>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-insufficient-balance')
    },
  },
}</script>
